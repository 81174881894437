import React from "react";
import { graphql } from "gatsby";
import Homepage from "src/templates/homepage";

export const query = graphql`
	query HomepageQuery {
		page: sanityHomepage(_id: { regex: "/(drafts.|)001homepage/" }) {
			content: _rawContent(resolveReferences: { maxDepth: 10 })
		}
	}
`;

export const IndexPage = (props) => {
	const content = props.data.page.content;
	return <Homepage location={props.location} pageContext={content} />;
};

export default IndexPage;
